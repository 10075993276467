<template>
    <div class="default-bg">
        <div class="bg-1">
            <img src="../../../assets/img/bi/line-1.png" alt="" class="icon-1">
            <div class="mg-t-20">
                <div class="t1">
                    <CountTo :startVal="0" separator="" :endVal="data.allWorkerCntRes || 0" :decimals="decimal(data.allWorkerCntRes || 0)"
                        :duration="3000" />人
                </div>
                <div class="fs-14 mg-t-8 t2">入职</div>
            </div>
        </div>
        <div class="bg-2">
            <img src="../../../assets/img/bi/line-2.png" alt="" class="icon-2">
            <div class="mg-t-20">
                <div class="t1">
                    <CountTo :startVal="0" separator="" :endVal="data.totalInsureRes || 0" :decimals="decimal(data.totalInsureRes || 0)"
                        :duration="3000" />人
                </div>
                <div class="fs-14 mg-t-8 t2">投保</div>
            </div>
        </div>
        <div class="bg-3">
            <img src="../../../assets/img/bi/line-3.png" alt="" class="icon-3">
            <div class="mg-t-20">
                <div class="t1">¥<CountTo :startVal="0" separator="" :endVal="data.totalPayRes || 0" :decimals="decimal(data.totalPayRes || 0)"
                        :duration="3000" /></div>
                <div class="fs-14 mg-t-8 t2">发薪</div>
            </div>
        </div>
        <div class="bg-4">
            <img src="../../../assets/img/bi/line-4.png" alt="" class="icon-4">
            <div class="mg-t-20">
                <div class="t1">¥<CountTo :startVal="0" separator="" :endVal="data.totalGetRes || 0" :decimals="decimal(data.totalGetRes || 0)"
                        :duration="3000" /></div>
                <div class="fs-14 mg-t-8 t2">领薪</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { nearThirtyData } from '@/api/bi.ts';
import { CountTo } from 'vue3-count-to';

let data = ref({})

const decimal = computed(() => {
    return (number) => {
        if (number) {
            const num = number.toString().split('.')[1]
            return num?.length ?? 0
        }
        return 0
    }
})

const getData = () => {
    nearThirtyData({}, {
        loading: true
    }).then(res => {
        data.value = res
    })
}
defineExpose({
    getData
})
onMounted(()=> {
    getData()
})
</script>
<style lang="stylus" scoped>
.default-bg {
    width 100%
    height 752px
    background url('../../../assets/img/bi/center-default-bg.png') no-repeat
    background-size contain
    background-position center center
    margin 0 auto
    position relative
    color #fff
}
.bg-1 {
    position absolute
    left 55px
    top 116px
    width 260px
    height 88px
    background url('../../../assets/img/bi/icon-title-1.png') no-repeat
    background-size contain
    text-align right
    padding-right 105px
    box-sizing border-box
}
.icon-1 {
    width 91px
    height 29px
    position absolute
    right -40px
    bottom -25px
}
.t1 {
    font-family youSheBiaoTiHei
    font-size 18px
    background: linear-gradient(180deg, #FFFFFF 25%, #9BE5FF 57%, #0DCAF5 73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.bg-2 {
    position absolute
    right 75px
    top 116px
    width 260px
    height 88px
    background url('../../../assets/img/bi/icon-title-2.png') no-repeat
    background-size contain
    text-align left
    padding-left 105px
    box-sizing border-box
}
.icon-2 {
    width 119px
    height 99px
    position absolute
    left -64px
    bottom -53px
}
.bg-3 {
    position absolute
    left 55px
    top 330px
    width 260px
    height 88px
    background url('../../../assets/img/bi/icon-title-3.png') no-repeat
    background-size contain
    text-align right
    padding-right 105px
    box-sizing border-box
}
.icon-3 {
    width 123px
    height 27px
    position absolute
    right -75px
    bottom -24px
}
.bg-4 {
    position absolute
    right 25px
    top 390px
    width 260px
    height 88px
    background url('../../../assets/img/bi/icon-title-4.png') no-repeat
    background-size contain
    text-align left
    padding-left 105px
    box-sizing border-box
}
.icon-4{
    width 166px
    height 156px
    position absolute
    left -125px
    bottom -88px
}
.mg-t-8 {
    margin-top 8px
}
.t2 {
    text-shadow 0px 0px 5.4px rgba(30, 198, 255, 0.8)
}
</style>