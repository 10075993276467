<template>
  <div class="content-box">
    <boxContainer title="最新招聘岗位" :loading="loading" no-background imgSize="big" long :height="500">
      <template #default>
        <el-carousel ref="carouselRef" height="535px" :interval="15000" direction="vertical"
          arrow="never" indicator-position="none" @change="handelSwiperChange">
          <el-carousel-item v-for="(item, index) in jobData" :key="index" class="h-full">
            <div class="content-wrap flex flex-column flex-jc-c">
              <div class="content-contaienr">
                <div class="fs-28 bold">{{ item.jobName }}</div>
                <div class="flex flex-jc-sb set-pd">
                  <div class="flex flex-column flex-jc-sb">
                    <div class="fs-36 fc-orange t1 mg-t-35 mg-b-35">{{ item.salary }}{{ item.jobSalaryType }}</div>
                    <div class="content">
                      <div v-html="item.jobDetail"></div>
                    </div>
                    <div class="flex flex-ai-c">
                      <img class="icon-company mg-r-10" src="../../../assets/img/bi/icon-company.png" alt="">
                      <div>
                        <div class="fs-14">{{ item.serveCompanyName }}</div>
                        <div class="fc-light-gray fs-12 mg-t-5">{{ item.jobAddress }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-column">
                    <div class="icon-img-box">
                      <img v-if="item.hasImage" class="icon-img" :src="item.params.file[0].fileUrl" alt="">
                      <img v-else class="icon-img" :src="item.qrCode" alt="">
                    </div>
                    <div v-if="!item.hasImage" class="text-center mg-t-20">扫描二维码 解锁更多好工作</div>
                  </div>
                </div>
                <div v-if="item.hasImage" class="flex flex-ai-c flex-jc-sb">
                  <div class="bottom-box">
                    <div class="mg-t-30">扫描二维码，解锁更多好工作</div>
                  </div>
                    <div class="icon-wx-box flex-no-shrink">
                      <img :src="item.qrCode" alt="" class="icon-wx">
                    </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </template>
    </boxContainer>
    <div class="shadow-box">
      <div class="shadow"></div>
      <div class="shadow2"></div>
    </div>
    <div class="img-bottom"></div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import boxContainer from '@/components/box-container/index.vue';
import { jobList, jobQrcode } from '@/api/bi.ts';

const emits = defineEmits(['hasData'])

let jobData = ref([])

const hasImage = computed(() => {
  return false
})

let carouselRef = ref(null)
let jobBase64 = ref('')
let loading = ref(false)

const getData = async () => {
  loading.value = true
  const data = await jobList({}, {
    loading: true
  })
  loading.value = false
  jobData.value = data || []
  emits('hasData', jobData.value.length)
  let names = ['png', 'jpg', 'jpeg', 'gif']
  jobData.value.forEach(i => {
    if (i.params?.file?.length) {
      i.params.file = i.params.file.filter(k => names.find(j => k.fileUrl.toLowerCase().endsWith(j)))
    }
    i.hasImage = i?.params?.file?.length
    i.qrCode = ''
    i.jobDetailList = i.jobDetail ? i.jobDetail.split('\\n') : []
  })
  if (jobData.value?.length) {
    jobData.value[0].qrCode = await getQrCode(jobData.value[0])
  }
  if (carouselRef.value) {
    carouselRef.value.setActiveItem(0)
  }
}

const getQrCode = (data) => {
  return new Promise((resolve) => {
    const query = {
      "page": "pages/job-detail/job-detail", // 跳转的页面
      "width": 100, // 大小
      "scene": `id=${data.id}&uid=${data.createUserId}&force=1`
    }
    jobQrcode(query, {
      headers: {
        loading: true
      }
    }).then(res => {
      const base64 = 'data:image/png;base64,' + res
      resolve(base64)
    })
  })

}

const handelSwiperChange = async (ev) => {
  if (!jobData.value[ev].qrCode) {
    jobData.value[ev].qrCode = await getQrCode(jobData.value[ev])
  }
}

defineExpose({
  getData
})

onMounted(() => {
  getData()
});
</script>
<style scoped lang="stylus">
.content-box {
  color #fff
  padding 0 60px
  position relative
  z-index 100
}
.content-wrap {
  height 535px
  padding 28px

  // backdrop-filter blur(4px)
  box-shadow 0px 4px 29px 0px rgba(0, 0, 0, 0.3)
  background radial-gradient(49% 1% at 50% 100%, rgba(39, 115, 255, 0.5) 0%, rgba(39, 115, 255, 0) 100%), radial-gradient(48% 3% at 50% 100%, rgba(39, 115, 255, 0.2) 0%, rgba(39, 115, 255, 0) 100%), radial-gradient(50% 15% at 50% 100%, rgba(39, 115, 255, 0.1) 0%, rgba(39, 115, 255, 0) 100%), linear-gradient(180deg, rgba(7, 27, 67, 0.55) 0%, #0D2E59 100%)
}
.shadow {
  box-shadow 0px 4px 29px 0px rgba(0, 0, 0, 0.3)
  background radial-gradient(49% 1% at 50% 100%, rgba(39, 115, 255, 0.5) 0%, rgba(39, 115, 255, 0) 100%), radial-gradient(48% 3% at 50% 100%, rgba(39, 115, 255, 0.2) 0%, rgba(39, 115, 255, 0) 100%), radial-gradient(50% 15% at 50% 100%, rgba(39, 115, 255, 0.1) 0%, rgba(39, 115, 255, 0) 100%), linear-gradient(180deg, rgba(7, 27, 67, 0.55) 0%, #0D2E59 100%)
  height 17px
  width 600px
  margin 0 auto
}
.shadow2 {
  box-shadow 0px 4px 29px 0px rgba(0, 0, 0, 0.3)
  background radial-gradient(49% 1% at 50% 100%, rgba(39, 115, 255, 0.5) 0%, rgba(39, 115, 255, 0) 100%), radial-gradient(48% 3% at 50% 100%, rgba(39, 115, 255, 0.2) 0%, rgba(39, 115, 255, 0) 100%), radial-gradient(50% 15% at 50% 100%, rgba(39, 115, 255, 0.1) 0%, rgba(39, 115, 255, 0) 100%), linear-gradient(180deg, rgba(7, 27, 67, 0.55) 0%, #0D2E59 100%)
  height 16px
  width 500px
  margin 0 auto
}
.content-contaienr {
  // backdrop-filter blur(-4px)
}
.mg-t-35 {
  margin-top 35px
}
.mg-b-25 {
  margin-bottom 25px
}
.t1 {
  font-family youSheBiaoTiHei
}
.fc-orange {
  color #E5751C
}
.icon-company {
  width 43px
  height 43px
  border-radius 6px
}
.fc-light-gray {
  color rgba(255, 255, 255, 0.6)
}
.icon-img-box {
  width 225px
  height 225px
  background url('../../../assets/img/bi/img-border.png') no-repeat
  background-size 100% 100%
  padding 17px
  box-sizing border-box
  margin-top 30px

}
.icon-img {
  width 100%
  height 100%
  border-radius 12px 
  object-fit cover
}
.lh-24 {
  line-height 24px
}
.bottom-box {
  width 100%
  height 98px
  background url('../../../assets/img/bi/bg-work.png') no-repeat
  background-size 100% 100%
  padding 0 15px 0 30px
  box-sizing border-box
  margin-top 5px
}
.icon-wx-box {
  width 120px
  height 120px
  background url('../../../assets/img/bi/wx-box.png') no-repeat
  background-size 100% 100%
  box-sizing border-box
  border-radius 10px
  overflow hidden
  margin-top 4px
  margin-left 20px
  padding 2px
}
.icon-wx {
  width 100%
  height 100%
  border-radius 36px
}
.img-bottom {
  width auto
  height 119px
  background url('../../../assets/img/bi/bottom-bg.png') no-repeat
  background-size 100% 100% 
}
.set-pd {
  padding-bottom 10px
}
.h-full {
  height 100%
}
.content {
  white-space pre-line
  overflow hidden
  text-overflow ellipsis
  display -webkit-box
  -webkit-line-clamp 5
  -webkit-box-orient vertical
}
.inner-bottom-box {
}
</style>
