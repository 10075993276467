<template>
    <div>
        <div class="title-head" :class="{'long-head': long}">
            <div class="title fs-18 fm-youshe">{{ title }}</div>
        </div>
        <slot name="header"></slot>
        <div v-show="!loading" class="title-body" :class="{'no-bg': noBackground}" :style="{height: heightValue}">
            <slot></slot>
        </div>
        <div v-show="loading" class="title-body flex flex-ai-c flex-jc-c" :class="{'no-bg': noBackground}" :style="{height: heightValue}">
            <img class="set-img" :class="{'small': imgSize === 'small'}" src="../../assets/img/bi/loading.gif" alt="">
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
const props = defineProps({
    title: {
        type: String,
        default: "职工实时动态"
    },
    height: {
        type: [Number, String],
        default: 300
    },
    long: {
        type: Boolean,
        default: false
    },
    noBackground: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    imgSize: {
        type: String,
        default: "small"
    }
})

const heightValue = computed(() => {
    return typeof props.height === "number" ? props.height + 'px' : props.height
})

</script>
<style lang="stylus" scoped>
.title-head {
    position relative
    width 100%
    height 39px
    background url('../../assets/img/bi/title-bg.png') no-repeat
    background-size 100% 100%
    color #fff
    // margin-bottom 5px
}
.long-head {
    background url('../../assets/img/bi/title-bg-long.png') no-repeat
    background-size 100% 100%
}
.title {
    position absolute
    left 40px
    top 9px
    font-style italic
}
.long-head {
    .title {
        top 8px    
    }
}
.title-body {
    overflow hidden
    background radial-gradient(26% 1% at 50% 100%, rgba(39, 115, 255, 0.5) 0%, rgba(39, 115, 255, 0) 100%), radial-gradient(36% 3% at 50% 100%, rgba(39, 115, 255, 0.2) 0%, rgba(39, 115, 255, 0) 100%), radial-gradient(63% 15% at 50% 100%, rgba(39, 115, 255, 0.1) 0%, rgba(39, 115, 255, 0) 100%), linear-gradient(180deg, rgba(7, 27, 67, 0.1) 0%, rgba(13, 46, 89, 0.2) 100%)
}
.no-bg {
    background initial
}
.fm-youshe {
    font-family youSheBiaoTiHei
}

.set-img {
    width 188px
    height auto
}
.small {
    width 94px
    height auto
}
</style>
