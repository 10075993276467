<template>
    <div class="flex center-bottom-box">
        <template v-for="(item, index) in data" :key="index">
            <centerBottomItem class="flex-1" :item-data="item" />
        </template>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import centerBottomItem from './center-bottom-item.vue';
import { nearThirtyData } from '@/api/bi.ts';
const data = ref({
    allWorkerCntRes: {
        title: '近30天入职',
        src: 'bi/icon-1.png',
        value: 0,
        prefix: '',
        suffix: '人'
    },
    totalPayRes: {
        title: '近30天发薪',
        src: 'bi/icon-2.png',
        value: 0,
        prefix: '¥',
        suffix: ''
    },
    totalGetRes: {
        title: '近30天领薪',
        src: 'bi/icon-3.png',
        value: 0,
        prefix: '¥',
        suffix: ''
    },
    totalInsureRes: {
        title: '近30天投保',
        src: 'bi/icon-4.png',
        value: 0,
        prefix: '',
        suffix: '人'
    },
})
const getData = () => {
    nearThirtyData({}, {
        loading: true
    }).then(res => {
        for(let key in data.value) {
            data.value[key].value = 0
            setTimeout(() => {
                data.value[key].value = res[key] || 0
            }, 0);
        }
    })
}
defineExpose({
    getData
})
onMounted(() => {
    getData()
})
</script>
<style lang="stylus" scoped>
.center-bottom-box {
    color #fff
    position relative
    z-index 1000
    background #060f2e
}
</style>