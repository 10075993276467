<template>
    <div ref="full" class="full-container">
        <scale-screen width="1920" height="1080" :delay="500" :fullScreen="false" class="bg" :boxStyle="{
            background: '#060f2e',
            overflow: isScale ? 'hidden' : 'auto',
        }" :wrapperStyle="wrapperStyle" :autoScale="isScale">
            <div class="content_wrap">
                <headers @click="handleClick" :button-name="buttonName"/>
                <top ref="top"/>
                <div class="flex pd-32 mg-t-24">
                    <div class="left">
                        <div class="left-container">
                            <leftTop ref="leftTop" />
                            <leftBottom ref="leftBottom" class="mg-t-20" />
                        </div>
                    </div>
                    <div id="center" class="center flex-1">
                        <div v-if="hasData" class="flex-1 flex flex-column flex-jc-end">
                            <centerTop ref="centerTop" @hasData="handleHasData"/>
                            <centerBottom ref="centerBottom" />
                        </div>
                        <div v-else>
                            <centerDefault ref="centerDefault"/>
                        </div>
                        <div class="bubble-box">
                        </div>
                    </div>
                    <div class="right">
                        <div class="right-container">
                            <rightRank ref="rightRank"/>
                            <rightTop ref="rightTop" class="mg-t-20" />
                            <rightBottom ref="rightBottom" class="mg-t-20" />
                        </div>
                    </div>
                </div>
            </div>
        </scale-screen>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import store from '@/store'
import screenfull from 'screenfull/dist/screenfull';
import scaleScreen from '@/components/scale-screen/scale-screen.vue';
import headers from './component/header.vue';
import top from './component/top.vue';
import leftTop from './component/left-top.vue';
import leftBottom from './component/left-bottom.vue';
import rightTop from './component/right-top.vue';
import rightBottom from './component/right-bottom.vue';
// import centerMap from './component/center-map.vue';
import centerTop from './component/center-top.vue';
import centerBottom from './component/center-bottom.vue';
import rightRank from './component/right-rank.vue';
import centerDefault from './component/center-default.vue';

export default defineComponent({
    components: {
        scaleScreen,
        headers,
        top,
        leftTop,
        leftBottom,
        rightTop,
        rightBottom,
        centerTop,
        centerBottom,
        rightRank,
        centerDefault
    },
    data() {
        return {
            isFull: true,
            isScale: true,
            hasData: true,
            timer: null,
            timer2: null
        }
    },
    computed: {
        buttonName(){
            return this.isFull ? '退出全屏' : '全屏'
        }
    },
    mounted() {
        setTimeout(() => {
            store.commit('setFullScreenContainer', this.$refs.full)
        }, 0);
        this.createBubble(20)
        this.timer && clearInterval(this.timer)
        this.timer = setInterval(() => {
            this.createBubble(20)            
        },3000);
        this.timer2 && clearInterval(this.timer2)
        this.timer = setInterval(() => {
            this.$refs?.top?.getData()
            this.$refs?.leftTop?.getData()
            this.$refs?.leftBottom?.getData()
            this.$refs?.centerTop?.getData()
            this.$refs?.centerBottom?.getData()
            this.$refs?.centerDefault?.getData()
            this.$refs?.rightRank?.getData()
            this.$refs?.rightTop?.getData()
            this.$refs?.rightBottom?.getData()
        },1000 * 60 * 5);
    },
    beforeDestroy() {
        clearInterval(this.timer)
        clearInterval(this.timer2)
    },
    beforeRouteLeave (to, from, next) {
        clearInterval(this.timer)
        clearInterval(this.timer2)
        next()
    },
    methods: {
        handleClick() {
            this.isFull = screenfull.isFullscreen
            screenfull.toggle(this.$refs.full)
            this.$router.go(-1)
        },
        handleFull() {
            // if (!screenfull.isEnabled) return
            // this.isFull = !this.isFull
            // console.log(this.$refs.full);
            screenfull.toggle(this.$refs.full)
            setTimeout(() => {
                this.$router.go(-1)
            }, 0);
            // if (this.isFull) {
            //     screenfull.request();
            // } else {
            //     screenfull.
            //     // Ignore or do something else
            // }
        },
        createBubble(n) {
            let container = document.querySelector('#center')
            let boundry = container.getBoundingClientRect()
            let bubbleContainer = document.querySelector('.bubble-box')
            let leftLimit = [container.offsetLeft, container.offsetWidth + container.offsetLeft - 50]
            let bottomLimit = [40,70]
            let doms = document.createDocumentFragment()
            let box = document.createElement('div')

            let widthLimit = [6, 14]
            for (let i = 0; i < n; i++) {
                let bubble = document.createElement('div')
                bubble.style.position = 'absolute'
                bubble.style.left = Math.floor(Math.random()*(leftLimit[1]-leftLimit[0]+1) + leftLimit[0]) + 'px';
                bubble.style.bottom = Math.floor(Math.random()*(bottomLimit[1]-bottomLimit[0]+1) + bottomLimit[0]) + 'px';
                let width = Math.floor(Math.random()*(widthLimit[1]-widthLimit[0]+1) + widthLimit[0])
                bubble.style.width = width + 'px';
                bubble.style.height = width + 'px';
                bubble.style.opacity = 0
                bubble.className = 'bubble'
                bubble.style['animation-delay'] = i * 1000 + 'ms'
                doms.appendChild(bubble)
            }
            box.appendChild(doms)
            container.appendChild(box)
            setTimeout(() => {
                container.removeChild(box)
            }, 10000);
        },
        handleHasData(data) {
            this.hasData = data
        }
    }
})
</script>
<style lang="stylus" scoped>
.content_wrap
    background url('../../assets/img/bi/bg.png') no-repeat
    background-size 100% 100%
.pd-32
    padding 0 32px
.left-container, .right-container
    width 460px
.mg-t-24
    margin-top 24px
::v-deep .bubble
    width 20px
    height 20px
    border-radius 50%
    background #07FFE1
    animation float 10s linear

@keyframes float {
    0% {
        transform translateY(0) scale(1)
        opacity 0.5
    }
    50% {
        transform translateY(-400px) scale(0.5)
        opacity 0.5
    }
    100% {
        transform translateY(-800px) scale(0.3)
        opacity 0
    }
}
.left {
    position relative
    z-index 100
}
.right {
    position relative
    z-index 100
}
.center {
    overflow hidden
}
</style>