import request from './request'
// 合作企业
export const serveCompanyInfos = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/serve-company-infos', {
        params: params,
        headers: options
    })
    return res
}
// 月度之星
export const monthRank = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/month-rank', {
        params: params,
        headers: options
    })
    return res
}
// 招聘动态一览
export const joinJob = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/join-job', {
        params: params,
        headers: options
    })
    return res
}
// 头部信息
export const headInfo = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/head', {
        params: params,
        headers: options
    })
    return res
}
// 轮播信息
export const scrollIngMsg = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/scrolling-message', {
        params: params,
        headers: options
    })
    return res
}
// 员工实时动态
export const workerInfos = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/worker-infos', {
        params: params,
        headers: options
    })
    return res
}
// 员工年龄分布信息
export const ageAnalyse = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/age-analyse', {
        params: params,
        headers: options
    })
    return res
}
// 岗位信息列表
export const jobList = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/job-list', {
        params: params,
        headers: options
    })
    return res
}
// 岗位二维码
export const jobQrcode = async (data: any, config: any) => {
    let res = await request.post('/common-blue-qrcode', data, config)
    return res
}
// 底部近三十天数据
export const nearThirtyData = async (params: any, options:any={}) => {
    let res = await request.get('/rest/pay/bi/near-thirty-data', {
        params: params,
        headers: options
    })
    return res
}
